import { useRef, useState } from "react";

export function useFormValidation() {
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  function checkFormValidity() {
    setIsFormValid(Boolean(formRef.current?.checkValidity()));
  }

  return { checkFormValidity, formRef, isFormValid };
}
